<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('geo_fencing.rfid_search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'

export default {
  name: 'geoFencingRfidManage',
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('geoFencingRfidManage'),
      view_permission: common.checkPermission('GeoFencing_RFID_List'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      status_map: [],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          // label: this.$t('common.member') + this.$t('member.chineseName'),
          label: this.$t('member.chineseName'),
          field: 'chineseName',
          width: '180px',
          changeHTML: function (value, row) {
            let avatar = ''
            if (row.photo) {
              avatar = common.getServerUrl() + row.photo
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary text-body rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          }
        },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '110px',
        },
        {
          label: 'member.status',
          field: 'status',
          change: this.changeStatus,
          width: '100px',
        },
        {
          label: 'geo_fencing.rfids',
          field: 'rfidTags',
          // width: '440px',
          changeHTML: (rfidStr, row) => {
            const rfids = rfidStr ? rfidStr.split(',') : []
            return rfids.map(rfid => `<span class="badge badge-light-secondary wh-font-family-monospace mr-05 font-weight-normal">${rfid}</span>`)
              .join('')
          }
        },
        {
          label: 'common.action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      header_actions: [],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'GeoFencing_RFID_Edit'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'statusList',
          label: 'member.status',
          type: 'select',
          multiple: true,
          options: []
        }
      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID') ?? null,
        roomID: common.getCustomConfigByKey('roomID') ?? null,
      }
    }
  },
  activated() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
      common.getSearchFloorOptions('floorID', this.filterValue.facilityID, this)
    }
    common.getSearchRoomOptions('roomID', this.filterValue.floorID, this)
    this.getList()

    common.getSearchMemberStatusOptions('statusList', this)
      .then(res => {
        this.status_map = res
      })
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/memberrfid/GetMemberRfids'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url)
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.memberRfids
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    editData: function (data) {
      this.$router.push({
        name: 'geoFencingRfidManage_edit',
        params: { id: common.encrypt(data.memberID) }
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
  }
}
</script>
